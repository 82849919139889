.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gradient {
  background-image: linear-gradient(135deg, #684ca0 35%, #1c4ca0 100%);
}

.lds-elliipsis{
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80%;
}
.lds-elliipsis div{
  position: absolute;
  top: 50%;
  bottom: 50%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-elliipsis div:nth-child(1){
  left: 8px;
  animation: lds-elliipsis1 0.6s infinite;
}
.lds-elliipsis div:nth-child(2){
  left: 8px;
  animation: lds-elliipsis2 0.6s infinite;
}
.lds-elliipsis div:nth-child(3){
  left: 32px;
  animation: lds-elliipsis2 0.6s infinite;
}
.lds-elliipsis div:nth-child(4){
  left: 56px;
  animation: lds-elliipsis3 0.6s infinite;
}

@keyframes lds-elliipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }  
}
@keyframes lds-elliipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-elliipsis2 {
  0% {
    transform: translate(0, 0);
  }
  0% {
    transform: translate(24px, 0)
  } 
}


.swiper-container {
  width: 800px;
  height: 400px;
  
}

.swiper-slide {
 width: 100%;
 height: 100%;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}