@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="date"],
textarea{
    @apply w-full text-gray-500 border border-gray-400 my-1 py-2 px-3 placeholder:text-slate-500 placeholder:text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500;
}
input[type="search"] {
    @apply w-full text-gray-500 !-my-2 px-4  placeholder:text-slate-500 placeholder:text-sm hover:bg-gray-200 rounded-full text-lg border-none  focus:outline-none focus:ring-1 focus:ring-gray-50 focus:border-gray-500;
}
textarea{
    height: 120px;
}
button {
    @apply bg-gray-300;
}
select{
    @apply w-full text-gray-500 border border-gray-400 my-1 py-2 px-3 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500;
}
button.primary {
    background-color: #f5385d;
    @apply bg-primary p-2 w-full text-white rounded-2xl;
}
h2 {
  @apply text-gray-800;
}

h3 {
    @apply text-gray-700;
}
h4 {
    @apply text-gray-500 !text-sm;
}

p, ol, ul {
    @apply text-gray-600;
}

ol {
    @apply list-decimal;
}

span {
    @apply text-sm sm:text-base text-gray-700;
}

sup {
    color: #f5385d;
}